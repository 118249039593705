// Here you can add other styles

.ag-header-cell {
    background-color: #d6ebff;
    text-align: center;
}

.icon {
    vertical-align: middle;
}

.btn {
    display: block;
}