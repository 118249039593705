/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "~@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "~@coreui/chartjs/scss/coreui-chartjs";

// perfect scrollbar theming for use with [perfectScrollbar] directive
//@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import "layout";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";

.alert {
  z-index: 2000;
  position: fixed;
  width: 100vw;
  text-align: right;
}
// /* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
.p-form-control {
  width: 100%;
  height: 38px;
  background-color: #fff !important;
  .p-placeholder {
      font-size: 16px;
      color: #222 !important;
  }

  .p-button{
      background-color: white;
      color: #000;
      border: 1px solid #b1b7c1
  }

  .p-button:enabled:hover {
      background: #b1b7c130;
      color: #000;
      border: 1px solid #b1b7c1;
      opacity: .8;
  }

}

.p-datepicker table td {
  padding: 0rem !important;
}
.p-datepicker .p-datepicker-header{
  padding: 0rem !important;

}
.p-datepicker .p-timepicker{
  padding: 0rem !important;

}
.p-datepicker .p-datepicker-buttonbar{
  padding: 0rem !important;

}
p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  right: 3.75rem !important;
}